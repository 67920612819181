import React from "react";
import f1 from "../../Assets/figs/f-1.jpg";
import f2 from "../../Assets/figs/f-2.jpg";
import f3 from "../../Assets/figs/f-3.jpg";
import f4 from "../../Assets/figs/f-4.jpg";
import f5 from "../../Assets/figs/f-5.jpg";
import f6 from "../../Assets/figs/f-6.jpg";
import f7 from "../../Assets/figs/f-7.jpg";
import f8 from "../../Assets/figs/f-8.jpg";
import f9 from "../../Assets/figs/f-9.jpg";
import f10 from "../../Assets/figs/f-10.jpg";
import f11 from "../../Assets/figs/f-11.jpg";
import f12 from "../../Assets/figs/f-12.jpg";
import { BlockImgCon, BlockImgPlay, BlockImgProj } from "../../component/BlockImgPlay";
import { BlockThumb, BlockTwoLn100 } from "../../component/Block";

const ImgFig = [
  f1,
  f2,
  f3,
  f4,
  f5,
  f6,
  f7,
  f8,
  f9,
  f10,
  f11,
  f12
]

function Inno() {
  return (
    <div>
      <BlockImgProj images={ImgFig}  gtcD="repeat(2, 50%)" gtcM="repeat(1, 100%)"/>
      
    </div>
  );
}

export default Inno;
