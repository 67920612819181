import React from "react";
import { useNavigate } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { color, space, layout, flexbox, position, width, zIndex } from "styled-system";

import { base } from "../theme";
import { Thumbnail, ThumbnailFont, H1, Text, Textcn, Button, Container } from "./Core";

export const Block = styled.div`
background-color: ${(props) => props.theme.colors.gre10};
display: flex;
flex-direction: row;
width: 100vw;
flex-wrap: wrap;
${zIndex};
`

export const BlockContainer5050 = styled.div`
background-color: ${(props) => props.theme.colors.gre10};
box-shadow: 0px 0px 1px ${(props) => props.theme.colors.gre20} inset;
${color};
${space};
${layout};

`

export const CenterWrap = styled.div`
${layout};
${width};
${position};
${flexbox};
`
const BImage = styled.img`
    flex-grow: 1;
    width: 100%;
    height: auto;
    object-fit: cover;
    ${space};
    ${layout};
`

const ImgBlock2 = styled.img`
    flex-grow: 1;
    object-fit: cover;
    object-position: center;
    ${space};
    ${layout};
`
export const BlockImg = (props) => {
  return (
    <ThemeProvider theme={base}>
      <BImage src={props.image} pb={[0]} />
    </ThemeProvider>
  )
};



export const Blockitem = (props) => {
  return (
    <ThemeProvider theme={base} >
      <Container Display={"flex"} flexDirection={"column"}>
        <ImgBlock2 src={props.image} pb={[1]} />
        <Text pb="1" fontFamily={[0]} color="lightgrey" fontSize={[0, 0, 1, 2]}>{props.title}</Text>
        <Text pb="" pr={[0, 3, 4, 5]} fontFamily={[0]} color="black" fontSize={[0, 0, 1, 2]}>{props.body}</Text>
      </Container>
    </ThemeProvider>
  )
};

export const BlockMenu = (props) => {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate("/");
  };
  const navigateAbout = () => {
    navigate("/about");
  };
  const navigateSnap = () => {
    navigate("/snap");
  };
  const navigateShop = () => {
    navigate("/Shop");
  };

  return (
    <ThemeProvider theme={base}>
      <Container width="100%" >
        <Button fontFamily={[0]} width="25%" fontSize={[1]} padding={[3]} variant="default" flexGrow="1" textAlign="left" onClick={navigateHome}> Noname </Button>
        <Button fontFamily={[0]} width="25%" fontSize={[1]} padding={[3]} variant="default" flexGrow="1" textAlign="left" onClick={navigateSnap}> Archive </Button>
        <Button fontFamily={[0]} width="25%" fontSize={[1]} padding={[3]} variant="default" flexGrow="1" textAlign="left" onClick={navigateAbout}> About </Button>
        <Button fontFamily={[0]} width="25%" fontSize={[1]} padding={[3]} variant="default" flexGrow="1" textAlign="left" onClick={navigateShop}> Shop </Button>
      </Container>
    </ThemeProvider>
  )
};





export const BlockFooter = (props) => {
  const navigate = useNavigate();

  const navigateLicense = () => {
    navigate("/License");
  };
  const navigatePrivacy = () => {
    navigate("/Privacy");
  };


  return (
    <ThemeProvider theme={base}>
      <Container className="block-footer" justifyContent="start" flexDirection={["row"]} flexWrap="wrap" zIndex="1" width={[1]} pt="3" pb="4" bg="#000" color="grey">

        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footer" textAlign="left" onClick={navigatePrivacy}> Privacy </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footer" textAlign="left" onClick={navigateLicense}> License </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footer" textAlign="left" onClick={navigateLicense}> Shipping </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footer" textAlign="left" onClick={() => window.open("https://www.instagram.com/z.type.nn/", "_blank")}> Instagram </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footer" textAlign="left" onClick={() => window.open("https://savee.it/tszhoip/", "_blank")}> Savee.it </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footer" textAlign="left" onClick={() => window.open("https://www.cosmos.so/tszhoip", "_blank")}> Cosmos.so </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footerDisable" textAlign="left" > 626 390 2555 </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footerDisable" textAlign="left" > info@noname.com </Button>
      </Container>
    </ThemeProvider>
  )
};


export const BlockThumb = (props) => {
  const { width, ...restProps } = props;
  return (
    <ThemeProvider theme={base}>
      <Thumbnail {...restProps} width={width} height={["auto"]} display="flex" flexDirection="column" to={props.to}>
        <ImgBlock2 height={props.height} src={props.img} side={props.side} />
        <Text fontFamily={[0]} fontSize={[1]} p={[3]} >{props.title}</Text>
      </Thumbnail>
    </ThemeProvider>
  )
}

export const BlockThumbFont = (props) => {
  const { width, ...restProps } = props;
  return (
    <ThemeProvider theme={base}>
      <ThumbnailFont {...restProps} width={width} height={["auto"]} display="flex" flexDirection="column" to={props.to}>
        <ImgBlock2 height={props.height} src={props.img} to={props.linkto} side={props.side} />
        <Container display="flex" flexDirection="row" justifyContent={"space-between"} alignItems={["center"]}>
          <Text fontFamily={[0]} fontSize={[1]} p={[3]} >{props.title}</Text>
          <Button padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footer" to={props.to} > BUY IT </Button>
        </Container>
      </ThumbnailFont>
    </ThemeProvider>
  )
}

export const BlockThumbFull = (props) => {
  const { width, ...restProps } = props;
  return (
    <ThemeProvider theme={base}>
      <Thumbnail {...restProps} width={width} height={["350px", "400px", "450px", "500px"]} to={props.to}>
        <Text fontFamily={[0]} fontSize={[1]} p={[3]}>{props.title}</Text>
      </Thumbnail>
    </ThemeProvider>
  )
}


export const BlockType = (props) => {
  return (
    <ThemeProvider theme={base}>
      <Container pl={[3]} flexDirection={["column", "column", "column", "column"]} flexWrap="wrap" justifyContent="center" width={[3 / 4, 2 / 3, 1 / 2, 1 / 2]}>
        <H1 fontFamily={[0]} fontSize={[1, 0, 0, 0]} >{props.title}</H1>
        <Text fontFamily={[0]} color={"blk40"} fontSize={[1, 0, 0, 0]}>{props.body}</Text>
      </Container>
    </ThemeProvider>
  )
}



export const BlockHeader = ({ title, description, buttonText, handleBuyClick, zIndex }) => {
  return (
    <ThemeProvider theme={base}>
      <Container width={["100%", "100%", "100%", "100%"]} position="fixed" top="0" flexDirection={["row"]} zIndex="999" height={["64px", "64px", "64px", "64px"]}>
        <Container flexGrow={["6", "6", "6", "6"]} p="3" flexDirection="column" justifyContent="center">
          <H1 fontFamily={[0]} fontSize={[1]} fontWeight="600">{title}</H1>
          <H1 fontFamily={[0]} fontSize={[1]} fontWeight="400">{description}</H1>
        </Container>
        <Container p="2">
          <Button fontFamily={[0]} fontSize={[1]} p="3" flexShrink="1" fontWeight="600" variant="primaryR" fle onClick={handleBuyClick} >{buttonText}</Button>
        </Container>
      </Container>
    </ThemeProvider>
  )
}



export const Block2Col = ({ col1, col2, pb }) => {
  return (
    <ThemeProvider theme={base}>
      <Container width={["100%"]} pb={[pb]} borderBottom="1px lightgrey solid">
        <Container flexGrow="4" width={["50%"]} p={[3]} flexDirection="row" justifyContent="left">
          <Text fontFamily={[0]} width={["90%"]} color={["blk50"]} variant="" fontSize={[1]}>{col1}</Text>
        </Container>
        <Container flexGrow="4" width={["50%"]} p={[3]} flexDirection="row" justifyContent="left">
          <Text fontFamily={[0]} width={["90%"]} color={["blk50"]} variant="" fontSize={[1]}>{col2}</Text>
        </Container>
      </Container>
    </ThemeProvider>
  )
}

export const Block4Col = ({
  col1,
  col2a, col2b, col2c, col2d,
  col3a, col3b, col3c, col3d,
  col4a, col4b, col4c, col4d, pb }) => {
  return (
    <ThemeProvider theme={base}>
      <Container width={["100%"]} pb={[pb]} borderBottom="1px lightgrey solid">
        <Container flexGrow="4" width={["25%"]} p={[3]} flexDirection="row" justifyContent="left">
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]}>{col1}</Text>
        </Container>
        <Container flexGrow="4" width={["25%"]} p={[3]} flexDirection="column" gap={["16px"]} justifyContent="left">
          <Text fontFamily={[0]} color={["blk50"]} variant="" pb={[1]} fontSize={[1]}>{col2a}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" pb={[1]} fontSize={[1]}>{col2b}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" pb={[1]} fontSize={[1]}>{col2c}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" pb={[1]} fontSize={[1]}>{col2d}</Text>
        </Container>
        <Container flexGrow="4" width={["25%"]} p={[3]} flexDirection="column" gap={["16px"]} justifyContent="left">
          <Text fontFamily={[0]} color={["blk50"]} variant="" pb={[1]} fontSize={[1]}>{col3a}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" pb={[1]} fontSize={[1]}>{col3b}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" pb={[1]} fontSize={[1]}>{col3c}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" pb={[1]} fontSize={[1]}>{col3d}</Text>
        </Container>
        <Container flexGrow="4" width={["25%"]} p={[3]} flexDirection="column" gap={["16px"]} justifyContent="left">
          <Text fontFamily={[0]} color={["blk50"]} variant="" pb={[1]} fontSize={[1]}>{col4a}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" pb={[1]} fontSize={[1]}>{col4b}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" pb={[1]} fontSize={[1]}>{col4c}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" pb={[1]} fontSize={[1]}>{col4d}</Text>
        </Container>
      </Container>
    </ThemeProvider>
  )
}

export const BlockList2 = ({ title, description }) => {
  return (
    <ThemeProvider theme={base}>
      <Container width={["100%"]} borderBottom="1px lightgrey solid">
        <Container width={["50%"]} flexDirection="row" justifyContent="left">
          <Text fontFamily={[0]} color={["blk70"]} variant="" fontSize={[1]} p={[3]}>{title}</Text>
        </Container>
        <Container width={["25%"]} flexDirection="column" justifyContent="left" pt={[3]} pb={[3]}>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
        </Container>
        <Container width={["25%"]} flexDirection="column" justifyContent="left" pt={[3]} pb={[3]}>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
        </Container>
      </Container>
    </ThemeProvider>
  )
}

export const Block1Col = ({ Headline, Subhead, Height, Width }) => {
  return (
    <ThemeProvider theme={base}>
      <Container height={{ Height }} width={{ Width }} pt={[2]} pb={[6]} flexDirection="column" justifyContent="left">
        <H1 fontFamily={[0]} width={["90%"]} color={["blk50"]} fontSize={[4]} p={[3]}>{Headline}</H1>
        <Text fontFamily={[0]} width={["90%"]} color={["blk50"]} fontSize={[2]} p={[3]}>{Subhead}</Text>
      </Container>
    </ThemeProvider>
  )
}

export const BlockProjTitle = ({ Project, Role, En, Jp, Cn }) => {
  return (
    <ThemeProvider theme={base}>
      <Container width={[1]} pt={[2]} pb={[3, 4, 5, 6]} flexDirection={["column", "column", "row", "row"]} justifyContent="left">
        <Container width={[1, 1, 1 / 2, 1 / 2]} flexDirection={"row"}>
          <Container width={"50%"} flexDirection={"column"} pt={[3]}>
            <Text fontFamily={[0]} color={["blk70"]} fontSize={[1]} pl={[3]}>Project</Text>
            <Text fontFamily={[0]} color={["blk50"]} fontSize={[1, 1, 1, 2]} pl={[3]}>{Project}</Text>
          </Container>
          <Container width={"50%"} flexDirection={"column"} pt={[3]}>
            <Text fontFamily={[0]} color={["blk70"]} fontSize={[1]} pl={[3]}>Role</Text>
            <Text fontFamily={[0]} color={["blk50"]} fontSize={[1, 1, 1, 2]} pl={[3]}>{Role}</Text>
          </Container>
        </Container>
        <Container width={[1, 1, 1 / 2, 1 / 2]} flexDirection={["column", "row", "row", "row"]}>
          <Container width={[1, 3 / 4, 7 / 8, 3 / 4]} flexDirection={"column"} pt={[3]}>
            <Text fontFamily={[0]} color={["blk70"]} fontSize={[1]} pl={[3]}>EN</Text>
            <Text fontFamily={[0]} color={["blk50"]} fontSize={[1, 1, 1, 2]} pl={[3]} pb={[3]}>{En}</Text>
            <Text fontFamily={[0]} color={["blk70"]} fontSize={[1]} pl={[3]}>CN</Text>
            <Textcn fontFamily={[0]} color={["blk50"]} fontSize={[1, 1, 1, 2]} m={[0]} pl={[3]} pr={[3]}>{Jp}</Textcn>
          </Container>
        </Container>
      </Container>
    </ThemeProvider>
  )
}