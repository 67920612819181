import React from "react";
import { BlockImg, Blockitem } from "../component/Block";
import { Container } from "../component/Core";
import ca1 from "../Assets/CanooB/1.01.jpg";
import ca2 from "../Assets/CanooB/1.02.jpg";
import ca3 from "../Assets/CanooB/1.03.jpg";
import ca4 from "../Assets/CanooB/1.04.jpg";
import ca5 from "../Assets/CanooB/1.05.jpg";
import ca6 from "../Assets/CanooB/1.06.jpg";
import ca7 from "../Assets/CanooB/1.07.jpg";
import ca8 from "../Assets/CanooB/1.08.jpg";
import ca9 from "../Assets/CanooB/1.09.jpg";
import ca10 from "../Assets/CanooB/1.10.jpg";
import { flexDirection } from "styled-system";


function CanooB() {

  const ImgCanooB = [
    { img: ca1, colSpan: 2, rowSpan: 1 },
    { img: ca2 },
    { img: ca3 },
    { img: ca4 },
    { img: ca5 },
    { img: ca6 },
    { img: ca7 },
    { img: ca8 },
    { img: ca9 },
    { img: ca10 }
  ]

  return (
    <Container width={[1]} flexDirection="column" flexWrap="wrap" alignItems="center">
      <BlockImg image={ca1} />
      <BlockImg image={ca2} />
      <BlockImg image={ca3} />
      <BlockImg image={ca4} />
      <BlockImg image={ca5} />
      <BlockImg image={ca6} />
      <BlockImg image={ca7} />
      <BlockImg image={ca8} />
      <BlockImg image={ca9} />
      <BlockImg image={ca10} />
    </Container>
  );
}

export default CanooB;
