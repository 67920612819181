import React from "react";
import lo1 from "../Assets/about/about-1.jpg";
import lo2 from "../Assets/about/about-2.jpg";

import { Container} from "../component/Core";
import { base } from "../theme";
import { ThemeProvider} from "styled-components";
import { Block1Col ,Block2Col, Block4Col, BlockImg} from "../component/Block";

function About( ) {

  return (
    <ThemeProvider theme={base}>
      <Container width={[1]} display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left">
      <Block1Col Width={"50%"} Headline={"Noname creates clean, impactful brands. We blend Bauhaus principles with strategic thinking to build brands that resonate."} Subhead={" "}/>
      <Block2Col pb={[5]}
        col1={"We like to keep things straightforward, so we always start with a clear process. We take the time to get to know you, your business, and your purpose, so our work is always grounded in a true understanding. We work collaboratively; bringing in talented creatives to ensure each project has the right team, with the right scale and skill set. We work internationally with businesses of all sizes. For more information, please get in touch."} 
        col2={"我们喜欢让事情简单明了，所以我们总是从一个清晰的流程开始。我们花时间了解您、您的业务和您的目的，因此我们的工作始终基于真正的理解。我们协同工作；引进才华横溢的创意人员，确保每个项目都有合适的团队、合适的规模和技能。我们与各种规模的企业开展国际合作。如需了解更多信息，请联系我们。"}
         />
     
     <Block4Col pb={[5]}
        col1={"Services"} 
        col2a={"Branding"}
        col2b={"Brand Strategy "} 
        col2c={"Naming "} 
        col2d={"Print"} 
        col3a={"Web"}
        col3b={"Digital Product"} 
        col3c={"Design System"} 
        col3d={"Prototyping"} 
        col4a={"App"}
        col4b={"Saas"} 
        col4c={""} 
        col4d={""} 
         />

     <BlockImg image={lo1} />


    <Block2Col  pb={[5]} col1="Designer / Z (tsz ho ip)" col2={"With over 10 years of experience as a Designer based in Los Angeles, specialize in art direction & digital product, He has collaborated with agencies and businesses owner across various sectors, including renowned collaborations with clients such as Buck and Google. He possesses a strong expertise in Design System across applications. "} />
    <BlockImg image={lo2} />

    <Block2Col  pb={[5]} col1="Designer / M Han (Michelle Han)" col2={"With over 5 years of experience as a graphic designer and art director based in Los Angeles, mhan has collaborated with agencies and businesses across various sectors, including renowned collaborations with clients such as Buck and Google. He possesses a strong expertise in brand image development. his work has been acknowledged and featured in various media outlets such as The Brand Identity, The Dieline, and Mindsparkle Mag."} />





      </Container>
    </ThemeProvider>
  );
}

export default About;
