import React from "react";
import { BlockImg, BlockProjTitle } from "../component/Block";
import { Container } from "../component/Core";
import ca1 from "../Assets/CanooB/1.01.jpg";
import ca2 from "../Assets/CanooB/1.02.jpg";
import ca3 from "../Assets/CanooB/1.03.jpg";
import ca4 from "../Assets/CanooB/1.04.jpg";
import ca5 from "../Assets/CanooB/1.05.jpg";
import ca6 from "../Assets/CanooB/1.06.jpg";
import ca7 from "../Assets/CanooB/1.07.jpg";
import ca8 from "../Assets/CanooB/1.08.jpg";
import ca9 from "../Assets/CanooB/1.09.jpg";
import ca10 from "../Assets/CanooB/1.10.jpg";
import ca11 from "../Assets/CanooB/1.11.jpg";
import ca12 from "../Assets/CanooB/1.12.jpg";

function CanooB() {
  // const Thumb = [
  //   { img: ca1 }
  // ]
  const ImgSet = [
    { img: ca2 },
    { img: ca3 },
    { img: ca4 },
    { img: ca5 },
    { img: ca6 },
    { img: ca7 },
    { img: ca8 },
    { img: ca9 },
    { img: ca10 },
    { img: ca11 },
    { img: ca12 }
  ]

  return (
    <Container width={[1]} flexDirection="column" flexWrap="wrap" alignItems="center">
      <BlockProjTitle 
      Project={"Canoo Visual Identity"} 
      Role={"Design Lead"}
      En={"Canoo, a pioneer in electric mobility, shifted its focus from individual drivers to commercial fleets after going public. To align their brand with this strategic evolution, we helped them redefine their visual identity."} 
      Jp={"Canoo，作为电动汽车领域的先驱，自2018年以来一直引领行业发展。公司从最初专注于个人出行市场转向商用车领域，这一战略调整发生在IPO之后。为了与这一战略转变相一致，我们协助Canoo重塑了品牌形象。"} 
      />
      <BlockImg image={ca1} />
      {ImgSet.map((imageObj, index) => (
        <BlockImg key={index} image={imageObj.img} />
      ))}
    </Container>
  );
}

export default CanooB;
