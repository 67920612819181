import React from "react";
import { BlockImg, BlockProjTitle } from "../component/Block";
import { Container } from "../component/Core";
import ca1 from "../Assets/CanooW/2.01.jpg";
import ca2 from "../Assets/CanooW/2.02.jpg";
import ca3 from "../Assets/CanooW/2.03.jpg";
import ca4 from "../Assets/CanooW/2.04.jpg";

function CanooW() {
  // const ImgSet = [
  //   { img: ca1 },
  //   { img: ca2 },
  //   { img: ca3 },
  //   { img: ca4 }
  // ]

  return (
    <Container width={[1]} flexDirection="column" flexWrap="wrap" alignItems="center">
      <BlockImg image={ca1} />
      <BlockProjTitle
      Project={"Canoo Web Showroom"} 
      Role={"Design Lead"}
      En={"Canoo, as a startup, has been back & fore with their product since 2018, the company finally land on the first product, Delivery vehicle. This web campaign was initial to create a bold statement on shifting the big news."} 
      Jp={"Canoo 作為一家新創公司，自 2018 年以來就不斷推出產品，最終推出了第一個產品——送貨車。這次網路活動最初是為了就轉移重大新聞發表大膽聲明。"} 
      />
     
     <BlockImg image={ca2} />
     <BlockImg image={ca3} />
     <BlockImg image={ca4} />

    </Container>
  );
}

export default CanooW;
